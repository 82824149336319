<template>
  <div class="mt-10 grid grid-cols-4 gap-1 pt-8 md:grid-cols-10 md:gap-4">
    <UCard
      v-for="item in techItems"
      :key="item.id"
      class="flex flex-col overflow-hidden border-none p-2 duration-300 ease-in-out"
      direction="column"
      :ui="{ ring: '', background: '', body: { padding: '' } }"
      v-bind:class="{ 'opacity-25 grayscale': selectedProject?.technologies.includes(item.id) === false }"
    >
      <div class="flex flex-col items-center gap-4 text-center">
        <img :src="item.image" alt="Project image" class="h-8 w-8 object-contain" />
        <div class="text-norwap text-xs font-bold">{{ item.title }}</div>
      </div>
    </UCard>
  </div>
</template>
<script setup>
import { useNuxtApp } from "#app";
import { storeToRefs } from "pinia";
import { useTechStore } from "~/stores/techStore";

const techStore = useTechStore();
const { selectedProject } = storeToRefs(techStore);
const { $pocketbase } = useNuxtApp();

const { data: techItems } = await useAsyncData("techItems", async () => {
  const records = await $pocketbase.collection("technologies").getFullList();
  const host = "https://home-db.bogsynth.com/api/files/";
  records.forEach((r) => {
    r.image = `${host}${r.collectionId}/${r.id}/${r.icon}?thumb=256x256f`;
  });
  return records;
});
</script>

<style scoped></style>

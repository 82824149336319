<template>
  <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4 lg:grid-cols-3">
    <UCard
      v-for="item in projects"
      :key="item.id"
      class="flex flex-col overflow-hidden px-4 py-1 hover:cursor-pointer hover:bg-[#4a494911] md:py-5"
      direction="column"
      :ui="{ ring: '', background: '', body: { padding: '' }, shadow: '' }"
      @mouseenter="selectedProject = item"
      @mouseleave="selectedProject = null"
    >
      <NuxtLink :to="`/projects/${item.id}`">
        <div class="flex flex-row items-start gap-4">
          <div class="flex-shrink-0">
            <img
              :src="item.image"
              alt="Project image"
              class="h-14 w-14 rounded-md object-cover shadow-lg md:h-24 md:w-24"
            />
          </div>

          <div class="flex flex-1 flex-col text-left">
            <div class="text-lg font-bold md:mb-2">{{ item.title }}</div>
            <p class="m-0 text-xs text-gray-600">{{ item.shortDesc }}</p>
          </div>
        </div>
      </NuxtLink>
    </UCard>
  </div>
</template>
<script setup>
import { useNuxtApp } from "#app";
import { storeToRefs } from "pinia";
import { useTechStore } from "~/stores/techStore";

const { $pocketbase } = useNuxtApp();
const techStore = useTechStore();
const { selectedProject } = storeToRefs(techStore);

const { data: projects } = await useAsyncData("projects", async () => {
  const records = await $pocketbase.collection("projects").getFullList({
    sort: "-created",
    filter: "inactive = false",
  });
  const host = "https://home-db.bogsynth.com/api/files/";
  records.forEach((r) => {
    r.image = `${host}${r.collectionId}/${r.id}/${r.image}?thumb=256x256f`;
  });
  return records;
});

onMounted(() => {
  selectedProject.value = null;
});
</script>

<style></style>

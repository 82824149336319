import type { RecordModel } from "pocketbase";

export interface TechStore {
  selectedProject: RecordModel | null;
  tech: RecordModel[];
}

export const useTechStore = defineStore("tech", {
  state: () =>
    ({
      selectedProject: null,
      tech: [],
    }) as TechStore,
  getters: {},
  actions: {

  },
});
